// Here you can add other styles

.clickable > tbody > tr {
	cursor: pointer;
}

.form-control {
	color: hsl(0, 0%, 20%);
}

.form-control:focus {
	color: hsl(0, 0%, 20%);
}

.style-less-table {
	tr {
		background-color: white !important;
	}

	tr:hover {
		background-color: white !important;
	}

	th {
		border: none !important;
	}

	td {
		border: none !important;
	}
}

.changed-cell {
	background-color: #1ec992;
	color: white;
}

.swatch {
	padding: 5px;
	background: #ffffff;
	border-radius: 0.25rem;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
	display: inline-block;
	cursor: pointer;
	display: flex;
	justify-content: space-around;
	// flex-direction: row-reverse;
}

.color {
	width: 36px;
	height: calc(1.5em + 0.15rem);
	border-radius: 2px;
}

// COOKIEWALL PREVIEW STYLING

.harvest-cookiewall-block{

	font-family: "Arial";
	line-height: 1.6;
	max-width: 685px;

	h1,h2,h3,h4,h5,h6{
        font-weight: 600;
        line-height: 1.1;
        margin: 0 0 20px;
	}
}

.harvest-cookiewall-block__inner{
	padding: 40px 50px 25px;
}

.harvest-cookiewall-btn{
	font-size: 16px;
    line-height: 1.6em;
    font-weight: 500;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    min-width: 235px;
    padding: 17px 38px;
	width: 100%;
}

